<template lang="pug">
  div
    div.basic100.py-1(dense flat)
      v-container(style="max-width: 600px")
        .h6.primary500--text {{ $t('payment') }}
    v-container.pa-4.mb-4(style="max-width: 600px" id="main")
      payment-method(@save="savePayment($event)" :isSecondPayment="true" :processing.sync="isProcessing")
      order-summary.mt-6(:isReadOnly="true")
    
    .modals
      v-dialog(v-if="showSetAmountDialog" v-model='showSetAmountDialog', max-width='320')
        v-card
          v-card-text.pa-4
            .s1.basic700--text {{ $t('minimum_deposit_of') }} {{ this.$store.state.current.order.price.minPaymentAmount | displayPrice($currency) }} {{ $t('is_allowed_for_this_purchase') }}
            v-form.mt-4(ref="form")
              p.mb-1.label.basic600--text.text-uppercase {{ $t('amount') }}
              v-text-field.mt-2(v-model='paymentAmount' placeholder="0" background-color="basic200" height="40" outlined dense type="number" :rules="priceRules" :prefix="$currency.symbol")
            v-btn.button-AA-medium.white--text(block outlined color="info500" :style="{'background-color': $vuetify.theme.themes[$theme].infoT08}" depressed @click='clickPayPartialAmount()' :loading="isProcessing")
              | {{ $t('pay_this_amount') }}
            .p1.basic600--text.text-center.my-3 {{ $t('or') }}
            v-btn.button-AA-medium.white--text(block color="info500" depressed @click='proceedToPay(false)' :loading="isProcessing")
              div(v-if="lead.status == 'partial_payment'") {{ $t('pay_remaining_amount') }}
              div(v-else) {{ $t('pay_full_amount') }}
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentMethod from '@/components/PaymentMethod.vue'
import OrderSummary from '@/components/OrderSummary.vue'

export default {
  name: 'OnlinePayment',
  components: { PaymentMethod, OrderSummary },
  data: () => ({
    isFetching: false,
    isProcessing: false,
    paymentAmount: 0,
    showSetAmountDialog: false,
    payment: null,
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead'
    }),
    priceRules () {
      return [
        v => !!v || 'Amount is required',
        v => v != 0|| 'Amount is required',
        v => v >= this.$store.state.current.order.price.minPaymentAmount/this.$currency.multiplier || `Amount should not be below ${this.$currency.symbol} ${(parseFloat(this.$store.state.current.order.price.minPaymentAmount/this.$currency.multiplier)).toFixed(this.$currency.exponent)}`,
        v => v <= this.$store.state.current.order.price.amountDue/this.$currency.multiplier || `Amount should not be above ${this.$currency.symbol} ${(parseFloat(this.$store.state.current.order.price.amountDue/this.$currency.multiplier)).toFixed(this.$currency.exponent)}`,
      ]
    }
  },
  methods: {
    savePayment (payment) {
      this.payment = payment
      this.proceedToPay(false)
    },
    clickPayPartialAmount() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.proceedToPay(true)
    },
    proceedToPay (isPartial) {
      if (!this.payment.method) {
        return
      }
      if (this.payment.method === 'manual') {
        this.proceedManual()
        return
      } else if (this.lead.installment && !this.showSetAmountDialog) {
        this.showSetAmountDialog = true
        return
      }
      if (isPartial) {
        this.payment.data['amount'] = (parseFloat(this.paymentAmount)).toFixed(this.$currency.exponent).replace('.', '')
      } else {
        delete this.payment.data.amount
      }
      this.isProcessing = true
      this.$axios.post(`/transactions/`, this.payment.data)
        .then((resp) => {
          const uid = resp.data.uid
          this.$axios.get(`/transactions/${uid}/pay/`)
            .then((resp) => {
              const data = resp.data
              if (data.action === 'POST') {
                this.postData(data)
              } else if (data.action === 'GET') {
                location.href = data.url
              }
            })
            .catch(() => {
              this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
              this.isProcessing = false
            })
        })
        .catch((err) =>{
          console.log(err.response.data)
          if (err.response.data.message) {
            this.$store.commit('message/showMessage', ['error', err.response.data.message])
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
          this.isProcessing = false
        })
    },
    proceedManual () {
      this.$router.push(`/orders/${this.lead.pid}/bank_transfer`)
    }
  }
}
</script>

<style scoped>
</style>