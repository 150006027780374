<template lang="pug">
  v-card(outlined)
    v-card.pa-4(flat)
      div.d-flex
        div.s2.primary--text {{ $t('order_summary') }}
        v-spacer
      div(v-for="(product, index) in cart" :key="index")
        cart-item(:product="product" :isReadOnly="true")
    v-divider.basic300
    v-layout.my-6(v-if="isFetching")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    template(v-else)
      v-card.pa-4(flat)
        div.d-flex.py-2(v-if="subtotalAmount > 0")
          div.p2.primary--text {{ $t('subtotal') }}
          v-spacer
          div.p2.primary--text {{ subtotalAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="shippingFeeAmount > 0")
          div.p2.primary--text {{ $t('shipping') }}
          v-spacer
          div.p2.primary--text {{ shippingFeeAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="taxFeeAmount > 0")
          div.p2.primary--text {{ $t('tax') }}
          v-spacer
          div.p2.primary--text {{ taxFeeAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="serviceFeeAmount > 0")
          div.p2.primary--text {{ $t('service_fee') }}
          v-spacer
          div.p2.primary--text {{ serviceFeeAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="discountAmount > 0")
          div.p2.primary--text {{ $t('discount') }}
          v-spacer
          div.p2.danger500--text - {{ discountAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="promoDiscountAmount > 0")
          div.p2.primary--text {{ $t('promo_discount') }}
          v-spacer
          div.p2.danger500--text - {{ promoDiscountAmount | displayPrice($currency) }}
        template(v-if="paidAmount > 0")
          div.d-flex.py-2
            div.p2.primary--text {{ $t('amount_paid') }}
            v-spacer
            div.p2.danger500--text - {{ paidAmount | displayPrice($currency) }}
          div.d-flex.py-2
            div.s1.primary--text {{ $t('balance_due') }}
            v-spacer
            div.s1.primary--text {{ balanceAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-else)
          div.s1.primary--text {{ $t('total') }}
          v-spacer
          div.s1.primary--text {{ totalAmount | displayPrice($currency) }}

</template>

<script>
import { mapGetters } from 'vuex'
import CartItem from '@/components/cart/CartItem.vue'

export default {
  name: 'OrderSummary',
  components: { CartItem },
  props: ['isReadOnly'],
  data: () => ({
    isFetching: false,
    invoice: null,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      lead: 'current/getLead',
    }),
    payment: {
      get() {
        return this.parentPayment
      },
      set(newValue) {
        this.$emit('update:parentPayment', newValue)
      }
    },
    subtotalAmount () {
      if (!this.invoice) return 0
      return this.invoice.subtotal_amount
    },
    taxFeeAmount () {
      if (!this.invoice) return 0
      return this.invoice.tax_amount
    },
    serviceFeeAmount () {
      if (!this.invoice) return 0
      return this.invoice.service_fee
    },
    shippingFeeAmount () {
      if (!this.invoice) return 0
      return this.invoice.shipping_fee
    },
    discountAmount() {
      if (!this.invoice) return 0
      return this.invoice.total_discount
    },
    promoDiscountAmount() {
      if (!this.invoice) return 0
      return this.invoice.total_promo_discount
    },
    paidAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_paid
    },
    totalAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_amount
    },
    amountDue() {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
    minPaymentAmount() {
      if (!this.invoice.minimum_payment) return 0
      if (this.invoice.minimum_payment > this.amountDue) {
        return this.amountDue
      }
      return this.invoice.minimum_payment
    },
    balanceAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
  },
  mounted ()  {
    this.setCartFromLead()
    this.fetchInvoice()
  },
  methods: {
    fetchInvoice () {
      if (!this.lead.pid) {
        return
      }
      this.isFetching = true
      this.$axios.get(`/invoices/${this.lead.pid}/`)
        .then((response) => {
          this.invoice = response.data
          this.$store.commit('current/updateOrderPrice', { ready: true, total: this.totalAmount, amountDue: this.amountDue, minPaymentAmount: this.minPaymentAmount, paidAmount: this.paidAmount })
        })
        .catch(() => {})
        .finally(() => {
          this.isFetching = false
        })
    },
    async setCartFromLead () {
      this.$store.commit('cart/updateLeadCart', { cart: this.lead.products, pid: this.lead.pid })
      const cart = []
      await this.asyncForEach(this.lead.products, async (data) => {
        try {
          const resp = await this.$axios.get(`/products/${data.product.id}`)
          const product = resp.data
          if (!product.image && data.image) {
            product.image = data.image 
          }
          if (product.variant_for) {
            const resp2 = await this.$axios.get(`/products/${product.variant_for}`)
            const parentProduct = resp2.data
            if (!product.image && resp2.data.image) {
              product.image = resp2.data.image
            }
            product.parent_product = parentProduct
          }
          data.product = { ...product }
          cart.push(data)
          this.$store.commit('cart/updateCart', cart)
        } catch (error) {
          console.log(error)
        }
      })
    }
  }
}
</script>

<style scoped>
.v-sheet--outlined {
  border: thin solid var(--v-basic300) !important;
}
.leave-message >>> textarea::placeholder {
  color: var(--v-primary400);
  opacity: 1;
}
</style>